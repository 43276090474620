import React, { createContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Auth from '@aws-amplify/auth';
import { Hub } from '@aws-amplify/core';
import { useStaticQuery, graphql } from 'gatsby';

const AuthContext = createContext(null);

export default AuthContext;

function getUserInfo(setState) {
  Auth.currentUserInfo()
    .then((userInfo) => {
      // If user info is null, the user is signed out
      setState(userInfo || false);
    })
    .catch((err) => {
      // We're going to assume the user is logged out
      setState(false);
    });
}

/**
 * Configures AWS Amplify and renders a context provider of the current user info
 *
 * auth = null              indicates auth is loading
 * auth = false             indicates the user is not authenticated
 * auth = { ...userInfo }   indicates the user is authenticated
 */
export function AuthProvider({ children }) {
  const [auth, setAuth] = useState(null);

  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          amplifyAuthConfiguration {
            identityPoolId
            region
            userPoolId
            userPoolWebClientId
            authenticationFlowType
            oauth {
              domain
              scope
              redirectSignIn
              redirectSignOut
              responseType
            }
          }
        }
      }
    }
  `);
  const { amplifyAuthConfiguration } = data.site.siteMetadata;

  useEffect(() => {
    // Configure Amplify Auth
    Auth.configure(amplifyAuthConfiguration);

    // Continue to listen for auth events
    Hub.listen('auth', ({ payload: { event } }) => {
      switch (event) {
        case 'signIn':
          // User just signed in
          getUserInfo(setAuth);

        // no default
      }
    });

    // Immediately attempt to fetch the current user's info
    getUserInfo(setAuth);
  }, [amplifyAuthConfiguration]);

  // Auth is for the children
  return (
    <AuthContext.Provider value={auth}>
      {children}
    </AuthContext.Provider>
  );
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
