const React = require('react');

const { AuthProvider } = require('context/AuthContext');
require('./src/styles/global.css');

// eslint-disable-next-line react/prop-types
exports.wrapRootElement = ({ element }) => (
  <AuthProvider>
    {element}
  </AuthProvider>
);
