// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-account-js": () => import("./../../../src/pages/account.js" /* webpackChunkName: "component---src-pages-account-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-download-js": () => import("./../../../src/pages/download.js" /* webpackChunkName: "component---src-pages-download-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-synthall-js": () => import("./../../../src/pages/synthall.js" /* webpackChunkName: "component---src-pages-synthall-js" */),
  "component---src-templates-blog-index-index-js": () => import("./../../../src/templates/blog/Index/Index.js" /* webpackChunkName: "component---src-templates-blog-index-index-js" */),
  "component---src-templates-blog-post-post-js": () => import("./../../../src/templates/blog/Post/Post.js" /* webpackChunkName: "component---src-templates-blog-post-post-js" */)
}

